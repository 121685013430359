<template>
  <div class="notFound">
    <h2>Page not found</h2>
    <i class="fas fa-exclamation-triangle red--text ma-5" style="font-size: 40px"></i>
    <p class="ma-5">
      We're sorry, the page you requested could not be found. Please go back to the
      <router-link to="/">homepage</router-link>.
    </p>
  </div>
</template>

<script>
import Landing from "@/layouts/Landing";

export default {
  name: "NotFound",
  data() {
    return {
      items: []
    };
  },
  created() {
    this.$emit(`update:layout`, Landing);
  }
};
</script>
