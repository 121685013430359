<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    "
  >
    <h3>This is a development preview of Tectechain</h3>
    <p>To use the production version of Tectechain, please click <a href="https://tectechain.com">here</a></p>
    <p>Login as AN to enter:</p>
    <div>
      <v-text-field v-model="email" outlined :label="$t('loginEmail')"> </v-text-field>
      <v-text-field
        outlined=""
        v-model="password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        name="input-10-2"
        :label="$t('loginPassword')"
        value=""
        class="input-group--focused"
        @click:append="show1 = !show1"
        @keyup.enter="devLogin"
      ></v-text-field>
      <v-alert dense type="error" width="100%" outlined v-if="error"> Please log in as AN. </v-alert>
    </div>
    <v-btn @click="devLogin" color="primary" rounded x-large depressed>Login</v-btn>
  </div>
</template>

<script>
import config from "../../config";
import { userSignIn } from "../../services/authService";
export default {
  name: "DevelopmentLogin",
  data: () => ({
    email: "",
    password: "",
    show1: false,
    error: false,
    devToken: null
  }),
  methods: {
    devLogin() {
      userSignIn(this.$axios, config, this.email.toLowerCase(), this.password)
        .then((result) => {
          if (result.status === 200) {
            this.devToken = result.data.token;
          } else {
            this.error = true;
            return;
          }
        })
        .then(this.getUserProfile);
    },
    async getUserProfile() {
      await this.$store.dispatch("loadUserProfile", {
        axios: this.$axios,
        config: config,
        token: this.devToken
      });
      if (this.$store.state.userProfile.roleType === 0) {
        localStorage.setItem("devToken", this.devToken);
        this.$store.dispatch("resetState");
        this.$router.push("/").catch(() => {});
      } else {
        this.error = true;
      }
    }
  }
};
</script>

<style></style>
